:root {
  --color-dark-bootstrap: #212529;
  --color-grey: #dee2e6;
  --color-grey-secondary: #e7e7e7;
  --color-light-bootstrap: #f8f9fa;
  --color-light-manual: #f5f5f5;
  --color-white: #ffffff;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

.mh-100vh {
  min-height: 100vh;
}

.nav-dark .nav-link {
  border: 1px solid var(--color-light-manual);
  background-color: var(--color-light-manual);
  color: var(--color-dark-bootstrap);

  &:hover {
    opacity: 0.825;
    border: 1px solid var(--color-grey-secondary);
    background-color: var(--color-grey-secondary);
  }

  &.active {
    border: 1px solid var(--color-grey-secondary);
    background-color: var(--color-grey-secondary);
    color: var(--color-dark-bootstrap);
  }
}

.bg-light {
  background-color: var(--color-light-manual) !important;
}

.bg-grey-secondary {
  background-color: var(--color-grey-secondary) !important;
}

.border-light {
  border-color: var(--color-light-manual) !important;
}

.btn-light {
  background-color: var(--color-light-manual);
  border-color: var(--color-light-manual);
}

/*
Remove the default down-arrow icon from a Bootstrap dropdown toggle
(https://getbootstrap.com/docs/5.3/components/dropdowns/).
*/
.dropdown-toggle-no-arrow-icon {
  &::before,
  &::after {
    display: none !important;
  }
}

/*
Allow contents of a focused AG Grid row to render on top of other rows,
e.g. to render a full dropdown in a cell
(https://stackoverflow.com/a/53710528/24849998).
 */
.ag-row-focused-on-top {
  &.ag-row {
    z-index: 0;
  }

  &.ag-row.ag-row-focus {
    z-index: 1;
  }
}

.react-pdf__Page__canvas,
.react-pdf__Page__annotations {
  border-radius: 0.6rem;
}

hr {
  opacity: 1;
  color: var(--color-grey);
  border-color: var(--color-grey);
  background-color: var(--color-grey);
}

.ag-theme-quartz {
  --ag-borders: none !important;
  --ag-border-color: var(--color-grey-secondary) !important;
  --ag-background-color: var(--color-light-manual) !important;
  --ag-row-hover-color: var(--color-grey-secondary) !important;
  --ag-header-foreground-color: var(--color-dark-bootstrap) !important;
  --ag-header-background-color: var(--color-grey-secondary) !important;
  --ag-font-size: var(--bs-body-font-size) !important;
  --ag-foreground-color: var(--color-dark-bootstrap) !important;
  --ag-font-family: var(--bs-body-font-family) !important;
  border: 1px solid var(--color-grey-secondary) !important;
  border-radius: 0.6rem !important;
  outline: 0 solid #ccdcff !important;
  transition: outline-width 0.125s !important;

  &.dragging-and-dropping {
    outline-width: 0.25rem !important;
  }
}
